import { createGlobalState } from 'react-hooks-global-state';
import { InputErrorObject } from '../../lib/types';

type GlobalStateType = {
  showDuplicateModal: boolean,
  cadTool?: string,
  checksComplete?: boolean,
  isSaving?: boolean,
  inputErrors?: InputErrorObject,
};

const { useGlobalState } = createGlobalState<GlobalStateType>({
  showDuplicateModal: false,
  cadTool: 'Not set',
  checksComplete: false,
  isSaving: false,
  inputErrors: {},
});

export default useGlobalState;
